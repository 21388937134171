import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/base/layout"

export default function NotFoundPage() {
  const data = useStaticQuery(query);
  return (
    <Layout seo={data.strapiHome.seo} lang="en">
      <section class="e404">
        <div class="e404__main">
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </section>
    </Layout>
  );
}

const query = graphql`
  query {
    strapiHome {
      slug_en
      seo {
        meta_title_en
        meta_description_en
      }
    }
  }
`;